var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.artist)?_c('section',{staticClass:"hero is-medium is-primary"},[(!_vm.isAuthenticated)?_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container has-text-centered"},[_c('h3',{staticClass:"title is-2"},[_vm._v("\n        getnext.to "),_c('strong',[_vm._v(_vm._s(_vm.artist.nickname))]),_vm._v(", "+_vm._s(_vm.$t('andImmediately'))+"\n      ")]),_vm._v(" "),_c('h4',{staticClass:"subtitle is-4"},[_vm._v(_vm._s(_vm.$t('getExclusiveAccess')))]),_vm._v(" "),_c('a',{staticClass:"button is-outlined is-white is-large mt-20",attrs:{"href":"#"},on:{"click":function($event){_vm.showFollowerModal = true}}},[_c('strong',[_vm._v(_vm._s(_vm.$t('signUpNow')))])])]),_vm._v(" "),(_vm.showFollowerModal)?_c('modal',{attrs:{"backdropClose":true,"defaultHeader":false,"defaultFooter":true},on:{"close":_vm.closeFollowModal}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"is-overlay"},[_c('section',{staticClass:"scale-hack"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"modal-card animated slideInDown login-modal pt-10 pl-10 pr-10"},[_c('follower-login',{on:{"logged":_vm.closeFollowModal}})],1)])])])])]):_vm._e()],1):(_vm.user.nickname)?_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container has-text-centered"},[_c('h3',{staticClass:"title is-2"},[_vm._v("\n        "+_vm._s(_vm.$t('hey'))+" "),_c('strong',[_vm._v(_vm._s(_vm.user.nickname))]),_vm._v("! getnext.to "),_c('strong',[_vm._v(_vm._s(_vm.artist.nickname))]),_vm._v(", "+_vm._s(_vm.$t('andImmediately'))+"\n      ")]),_vm._v(" "),_c('h4',{staticClass:"subtitle is-4"},[_vm._v(_vm._s(_vm.$t('getExclusiveAccess')))]),_vm._v(" "),_c('div',{staticClass:"cta-purchasables"},[(_vm.artist.products.length)?_c('nuxt-link',{staticClass:"button is-outlined is-white is-large mt-20",attrs:{"to":_vm.localePath({
          name: 'id-products',
          params: { id: _vm.artist.urlAlias }
        })}},[_c('strong',[_vm._v(_vm._s(_vm.$t('availableSingleRewards')))])]):_vm._e(),_vm._v(" "),(_vm.artist.rewards.length)?_c('nuxt-link',{staticClass:"button is-outlined is-white is-large mt-20",attrs:{"to":_vm.localePath({
          name: 'id-rewards',
          params: { id: _vm.artist.urlAlias }
        })}},[_c('strong',[_vm._v(_vm._s(_vm.$t('availableRewards')))])]):_vm._e()],1)])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }