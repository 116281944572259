<template>
  <div id="login-pane">
    <div class="columns">
      <div v-if="!registrationSent" class="column is-10 is-offset-1">
        <h3 class="title is-3 has-text-centered mb-0 mt-20">
          {{ $t('follower.youAreFantastic') }}
        </h3>
        <p class="pt-20">
          {{
            $t('follower.followArtistToNeverMissUpdate', {
              artist: artist.nickname
            })
          }}
        </p>
        <form
          v-if="!registrationSent"
          novalidate
          method="post"
          @submit.prevent="submitCredentials"
        >
          <input-text
            v-model="email"
            id="email"
            :label="$t('email')"
            name="email"
            scope="follower"
            validate="required|validEmailAddress"
          />
          <p class="pt-20">
            {{ $t('follower.expectEmail') }}
          </p>
          <p class="pt-20">
            {{ $t('follower.accountNotification') }}
          </p>
          <div class="control mt-50">
            <button
              type="submit"
              :disabled="errors.any('follower')"
              class="button is-primary is-outlined is-fullwidth is-medium"
              id="submit-login"
            >
              {{ $t('submit') }}
            </button>
            <input type="submit" style="position: absolute; left: -9999px" />
          </div>
        </form>
      </div>
      <div v-else class="column is-10 is-offset-1">
        <h3 class="title is-3 has-text-centered mb-0 mt-20">
          {{ $t('thankYou') }}
        </h3>
        <p class="pt-20">
          {{ $t('follower.checkMailBox') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'loginComponent',
  serverCacheKey: () => 'follower-login',
  inject: ['$validator'],
  props: ['from', 'params'],
  data: () => ({
    email: '',
    hasError: false,
    registrationSent: false
  }),

  mounted() {
    this.$validator.localize(this.$i18n.locale)
  },

  computed: {
    ...mapState(['artist'])
  },

  methods: {
    async submitCredentials() {
      const valid = await this.$validator.validate('follower.*')
      if (valid) {
        const payload = {
          email: this.email,
          interfaceLanguage: this.$i18n.locale
        }

        const result = await this.apiPost(`/api/public/${this.artist.urlAlias}/invite-follower`, payload)
        if (result) {
          this.registrationSent = true
        }
      }
    }
  }
}
</script>
<style lang="scss">
#login-pane {
  h3 {
    color: #000000 !important;
    font-weight: 300 !important;
  }
  button {
    line-height: 1;
    padding: 10px 15px;
  }
}
.button-column-group {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  button.is-in-column {
    margin-left: 0rem !important;
  }
}
</style>
