<template>
  <div v-if="artist" class="on-body-background">
    <div v-if="postTags && postTags.length" class="post-tags mt-10 mb-15">
      <h5 class="mb-5 is-5 post-tags-headline">{{ $t('topics', { name: artist.nickname }) }}</h5>
      <div class="tags" :class="{ 'space-evenly': postTags.length > 3 }">
        <nuxt-link v-for="tag of postTags" :key="tag._id" :to="localePath({
          name: tagIds.includes(tag.id) ? 'id-posts' : 'id-posts-tag',
          params: tagIds.includes(tag.id) ? { id: artist.urlAlias } : { id: artist.urlAlias, tag: tag.slug },
        })" class="tag is-white" :class="{ 'is-primary': tagIds.includes(tag.id) }">
          {{ tag.name }}
        </nuxt-link>
      </div>
    </div>
    <div class="multi-select-filter">
      <filterPostsByReward :layout="layout" :clear="clearFilterByReward" @filter="handleFilter">
      </filterPostsByReward>
      <filterPostsByMonth :layout="layout" @filter="handleFilter">
      </filterPostsByMonth>
      <filterPostsByContent :layout="layout" @filter="handleFilter">
      </filterPostsByContent>
    </div>
    <div class="mb-10 mt-15 public-post-filter">
      <input type="checkbox" id="show-public-posts" class="switch is-small" name="show-public-posts"
        @change="showOnlyPublicPosts" :checked="filter.public" />
      <label for="show-public-posts" class="is-small">{{ $t('showOnlyPublicPosts') }}</label>
    </div>
    <div class="mb-10 public-post-filter">
      <input type="checkbox" id="show-follower-posts" class="switch is-small" name="show-follower-posts"
        @change="showOnlyFollowerPosts" :checked="filter.limitToFollowers" />
      <label for="show-follower-posts" class="is-small">{{ $t('showOnlyFollowerPosts') }}</label>
    </div>
    <button v-if="filtersActive" @click="resetFilters"
      class="filter-warning mb-10 button is-outline is-small is-secondary">
      Filter zurücksetzen
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import filterPostsByReward from '~/components/filter-posts-by-rewards'
import filterPostsByMonth from '~/components/filter-posts-by-month'
import filterPostsByContent from '~/components/filter-posts-by-content'

export default {
  props: ['layout'],
  components: {
    filterPostsByReward,
    filterPostsByMonth,
    filterPostsByContent
  },
  data: () => ({
    name: 'Filter-Posts',
    filter: {
      tags: [],
      product: [],
      reward: [],
      months: [],
      contents: [],
      public: false,
      limitToFollowers: false
    }
  }),
  computed: {
    filtersActive() {
      return this.postFilter.tags.length +
        this.postFilter.product.length +
        this.postFilter.reward.length +
        this.postFilter.months.length +
        this.postFilter.contents.length > 0 ||
        this.postFilter.public ||
        this.postFilter.limitToFollowers
    },
    clearFilterByReward() {
      return !this.filter.public && !this.filter.limitToFollowers
    },
    tagIds() {
      if (!this.postFilter) return []
      return this.postFilter.tags.map((t) => t.id)
    },
    ...mapState(['artist', 'profilePostsLoaded', 'profilePostsLoading', 'postFilter', 'postTags'])
  },
  methods: {
    resetFilters() {
      this.filter = {
        tags: [],
        product: [],
        reward: [],
        months: [],
        contents: [],
        public: false,
        limitToFollowers: false
      }
      this.resetPostFilter()
      this.$router.push(this.localePath({ name: 'id-posts', params: { id: this.artist.urlAlias } }))
    },

    showOnlyPublicPosts(event) {
      this.filter.public = event.target.checked
      this.filter.limitToFollowers = false
      this.filter.product = []
      this.filter.reward = []
      this.executeFilter()
    },

    showOnlyFollowerPosts(event) {
      this.filter.limitToFollowers = event.target.checked
      this.filter.public = false
      this.filter.product = []
      this.filter.reward = []
      this.executeFilter()
    },
    handleFilter(filter) {
      this.filter = this.postFilter
      if (
        (filter.product || []).length ||
        (filter.reward || []).length
      ) {
        this.filter.public = false
        this.filter.limitToFollowers = false
      }

      Object.keys(filter)
        .filter((key) => !['public', 'limitToFollowers'].includes(filter[key]))
        .forEach((key) => {
          this.filter[key] = filter[key]
        })

      this.executeFilter()
    },
    async executeFilter() {
      if (!this.filter) return
      this.setPostFilter({ filter: this.filter })
      if (!this.profilePostsLoading && !this.profilePostsLoaded) {
        await this.loadAllPosts({
          urlAlias: this.artist.urlAlias
        })
      }
    },
    ...mapActions(['loadAllPosts', 'setPostFilter', 'resetPostFilter'])
  },
  async mounted() {
    this.filter = Object.assign({}, this.postFilter)
    this.executeFilter()
  }
}
</script>

<style lang="scss" scoped>
.post-tags {
  .post-tags-headline {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .tags {
    padding-top: 10px;
    padding-bottom: 5px;
    border-top: 1px solid #ddd;

    &.space-evenly {
      justify-content: space-evenly;
    }
  }
}
</style>

<style lang="scss">
@import 'assets/sass/_variables';

.checkBoxContainer {
  max-height: 400px !important;
  max-width: 300px !important;

  li {
    margin-left: 5px;

    span {
      margin-left: 0px !important;
      white-space: normal;
      font-size: 0.75rem;
    }
  }
}

.filter-warning {
  display: block;
}

.multi-select {
  width: 100% !important;

  >button {
    width: 100% !important;
    border: 0px !important;
    border-radius: 0px !important;

    &:hover {
      color: #ffffff;
      background-color: var(--primary-color) !important;

      .caret {
        border-top-color: #ffffff !important;
      }
    }
  }

  &::after {
    border: none !important;
  }
}

.selectItem {
  &.active {
    color: var(--primary-text-color) !important;
  }
}

.tab-item {
  margin: 5px !important;

  &.active {
    span {
      color: var(--tiles-background-text-color) !important;
      border-bottom-color: var(--primary-color) !important;
    }
  }

  span {
    font-size: .75rem !important;
  }
}

@media only screen and (max-width: $mobile) {

  .filter-content-mobile,
  .filter-reward-mobile,
  .filter-month-mobile {
    font-size: 0.75rem !important;

    .helperButton {
      font-size: 0.75rem !important;
    }

    .btn-select {
      text-align: left !important;

      .buttonLabel {
        span {
          font-size: 0.75rem;
        }
      }
    }
  }

  .checkBoxContainer {
    li {
      span {
        font-size: 0.75rem;
      }
    }
  }
}
</style>
