var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs is-centered",attrs:{"id":"profile-navigation"}},[_c('ul',[_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath({
          name: 'id',
          hash: '#profile-navigation',
          params: { id: _vm.artist.urlAlias }
        })}},[_vm._v("\n        "+_vm._s(_vm.$t('profile')))])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath({ name: 'id-posts', params: { id: _vm.artist.urlAlias } })}},[_vm._v(_vm._s(_vm.$t('posts.posts'))+"\n      ")])],1),_vm._v(" "),(_vm.communityEnabled || _vm.myProfile)?_c('li',[_c('a',{attrs:{"href":`/messenger/${_vm.artist.urlAlias}`,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('community')))])]):_vm._e(),_vm._v(" "),(_vm.guestbookEnabled || _vm.myProfile)?_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath({
          name: 'id-guestbook',
          params: { id: _vm.artist.urlAlias }
        })}},[_vm._v(_vm._s(_vm.$t('guestbook'))+"\n      ")])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }