<template>
  <span class="arrow" v-show="rendered">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve">
      <g>
        <path d="M0,7l1.9-1.9L12,15.2L22.1,5.1L24,7l-12,12L0,7z"></path>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'arrowComponent',
  serverCacheKey: () => 'arrowComponent',
  data: () => ({
    rendered: false
  }),

  mounted() {
    this.rendered = true
  }
}
</script>

<style lang="scss">
@keyframes bouncearrow {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.arrow {
  transform-origin: center bottom;
  animation-name: bouncearrow;
  animation-duration: 2s;
  animation-delay: 6s;
  animation-iteration-count: infinite;
  display: inline-block;
  width: 32px;
  height: 32px;
  fill: #fff;
  text-shadow: rgba(0, 0, 0, 0.2) 0px 1px 0px;
}
</style>
