<template>
  <div class="tabs is-centered" id="profile-navigation">
    <ul>
      <li>
        <nuxt-link :to="
          localePath({
            name: 'id',
            hash: '#profile-navigation',
            params: { id: artist.urlAlias }
          })
        ">
          {{ $t('profile') }}</nuxt-link>
      </li>
      <li>
        <nuxt-link :to="
          localePath({ name: 'id-posts', params: { id: artist.urlAlias } })
        ">{{ $t('posts.posts') }}
        </nuxt-link>
      </li>
      <li v-if="communityEnabled || myProfile">
        <a :href="`/messenger/${artist.urlAlias}`" target="_blank">{{ $t('community') }}</a>
      </li>
      <li v-if="guestbookEnabled || myProfile">
        <nuxt-link :to="
          localePath({
            name: 'id-guestbook',
            params: { id: artist.urlAlias }
          })
        ">{{ $t('guestbook') }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    myProfile() {
      if (this.isAuthenticated) {
        return this.artist.nickname === this.user.nickname
      }
      return false
    },
    communityEnabled() {
      if (!this.artist.communityEnabled) return false
      return (
        this.artist.communityState === 'authenticated' &&
        this.isAuthenticated
      ) ||
      (
        this.artist.communityState === 'follower' &&
        this.artist.followed
      ) ||
      (
        this.artist.communityState === 'reward' &&
        this.artist.subscribed
      )
    },
    guestbookEnabled() {
      return this.artist.profile.configuration.guestbook &&
        this.artist.profile.configuration.guestbook.enabled
    },
    ...mapState(['artist', 'user', 'isAuthenticated'])
  }
}
</script>

<style lang="scss">
@import 'assets/sass/_variables';

#profilenav {
  z-index: 2;
  height: 52px;
  line-height: 1.5;
  padding: 0.4rem;
  text-shadow: rgba(0, 0, 0, 0.2) 0 1px 0;
  pointer-events: none;

  a {
    pointer-events: all;

    &.router-link-exact-active {
      background-color: #eee;
    }
  }

  &.js-is-sticky {
    border-bottom: none;
  }
}

@media only screen and (max-width: $mobile-portrait) {
  .tabs {
    a {
      font-size: $font-size-smaller;
    }
  }
}
</style>
