<template>
  <section v-if="artist && rewardOptions.length">
    <vue-multi-select
      ref="rewardMultiSelect"
      class="multi-select filter-reward"
      :class="{'filter-reward-mobile': layout === 'mobileFilterPosts'}"
      v-model="values"
      :options="options"
      :filters="filters"
      :btnLabel="btnLabel"
      :position="layout === 'mobileFilterPosts' ? '' : 'bottom-left'"
      :searchPlaceholder="`${$t('search')}...`"
      @selectionChanged="executeFilter"
      :selectOptions="rewardOptions">
    </vue-multi-select>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import vueMultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'

export default {
  props: ['layout', 'clear'],
  components: { vueMultiSelect },
  data: () => ({
    name: 'Filter-Posts-Reward',
    values: [],
    filters: [],
    options: {
      multi: true,
      groups: true,
      labelValue: '_id',
      cssSelected: (option) => option['selected'] ? { 'font-weight': 'bold', color: '#fb3e52' } : ''
    },
    filterInitialized: false
  }),
  watch: {
    clear: {
      handler(oldVal, newVal) {
        if (newVal === true) {
          this.values = []
        }
      }
    }
  },
  computed: {
    rewardOptions() {
      if (!this.artist) return []
      const options = []
      if (this.rewards.length) {
        options.push({
          name: 'Rewards',
          list: this.rewards
        })
      }
      if (this.singleRewards.length) {
        options.push({
          name: 'Single Rewards',
          list: this.singleRewards
        })
      }
      return options
    },
    singleRewards() {
      if (!this.artist) return []
      return this.artist.products.map(p => {
        return {
          _id: p._id,
          name: p.name,
          type: 'product'
        }
      })
    },
    rewards() {
      if (!this.artist) return []
      return this.artist.rewards.map(r => {
        return {
          _id: r._id,
          name: r.name,
          type: 'reward'
        }
      })
    },
    ...mapState(['artist', 'profilePostsLoaded', 'postFilter'])
  },
  methods: {
    btnLabel() {
      return this.$t('filterByRewards') + (this.values.length ? ` (${this.values.length})` : '')
    },
    cssSelected(option) {
      return option['selected'] ? {'color': 'green'} : ''
    },

    clearResult() {
      this.value = []
    },
    initFilter() {
      for (const productFilter of this.postFilter.product) {
        const product = this.artist.products.find(p => p._id === productFilter)
        if (product) {
          this.values.push({
            _id: product._id,
            name: product.name,
            type: 'product'
          })
        }
      }
      for (const rewardFilter of this.postFilter.reward) {
        const reward = this.artist.rewards.find(r => r._id === rewardFilter)
        if (reward) {
          this.values.push({
            _id: reward._id,
            name: reward.name,
            type: 'reward'
          })
        }
      }
    },
    restoreFilter() {
      this.filterInitialized = false
      this.values = []

      if (
        !this.postFilter ||
        (!this.postFilter.reward && !this.postFilter.product) ||
        (!this.postFilter.reward.length && !this.postFilter.product.length)
      ) {
        return this.clearResult()
      }

      for (const productFilter of this.postFilter.product) {
        const product = this.artist.products.find(p => p._id === productFilter)
        if (product) {
          this.values.push({
            _id: product._id,
            name: product.name,
            type: 'product'
          })
        }
      }

      for (const rewardFilter of this.postFilter.reward) {
        const reward = this.artist.rewards.find(r => r._id === rewardFilter)
        if (reward) {
          this.values.push({
            _id: reward._id,
            name: reward.name,
            type: 'reward'
          })
        }
      }
      this.filterInitialized = true
    },
    async executeFilter() {
      if (!this.filterInitialized) {
        this.filterInitialized = true
        return
      }

      const filter = { product: [], reward: [] }
      for (const purchasable of this.values) {
        filter[purchasable.type].push(purchasable._id)
      }

      this.$emit('filter', filter)
    }
  },
  mounted() {
    if (this.artist) {
      this.initFilter()
    }

    this.$root.$on('recoverPostFilter', this.restoreFilter)
    this.filterInitialized = true
  },
  beforeDestroy() {
    this.$root.$off('recoverPostFilter', this.restoreFilter)
  }
}
</script>
