<template>
  <div>
    <section>
      <vue-multi-select
        ref="multiSelectMonths"
        class="multi-select filter-month"
        :class="{'filter-month-mobile': layout === 'mobileFilterPosts'}"
        v-model="values"
        :options="options"
        :filters="filters"
        :btnLabel="btnLabel"
        :position="layout === 'mobileFilterPosts' ? '' : 'bottom-left'"
        :searchPlaceholder="`${$t('search')}...`"
        @selectionChanged="executeFilter"
        @open="open"
        :selectOptions="monthsOptions">
      </vue-multi-select>
    </section>
  </div>
</template>

<style lang="scss">
@import 'assets/sass/_variables';
@media only screen and (max-width: $mobile-portrait) {
  .filter-month-mobile {
    .checkboxLayer {
      min-width: 186px !important;
      max-width: 186px;
    }
  }
}

@media only screen and (min-width: $mobile-portrait) and (max-width: $mobile) {
  .filter-month-mobile {
    .checkboxLayer {
      min-width: 250px !important;
      max-width: 250px;
    }
  }
}
</style>

<script>
import { mapState } from 'vuex'
import vueMultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'

export default {
  props: ['layout'],
  components: { vueMultiSelect },
  data: () => ({
    name: 'Filter-Posts-Months',
    values: [],
    options: {
      multi: true,
      groups: true,
      labelValue: '_id',
      cssSelected: (option) => option['selected'] ? { 'font-weight': 'bold', color: '#fb3e52' } : ''
    },
    filters: [{
      nameAll: 'select all',
      nameNotAll: 'unselect all',
      // nameAll: this.selectAllLabel,
      // nameNotAll: this.deselectAllLabel,
      func() {
        return true
      }
    }],
    filterInitialized: false,
    calculatedMonths: [],
    firstOpen: true
  }),
  computed: {
    monthsOptions() {
      if (this.calculatedMonths.length) {
        return this.calculatedMonths
      }
      return this.monthsByYearSince(this.artist.createdAt, this.$i18n.locale)
    },
    selectAllLabel() {
      return 'select all'
    },
    deselectAllLabel() {
      return 'unselect all'
    },
    ...mapState(['artist', 'profilePostsLoaded', 'postFilter'])
  },
  methods: {
    btnLabel() {
      return this.$t('filterByMonths') + (this.values.length ? ` (${this.values.length})` : '')
    },

    cssSelected(option) {
      return option['selected'] ? {'color': 'green'} : ''
    },

    clearResult() {
      this.value = []
    },
    initFilter() {
      this.filters[0].nameAll = this.$t('selectYearAll')
      this.filters[0].nameNotAll = this.$t('deselectYearAll')
    },
    restoreFilter() {
      this.filterInitialized = false
      this.values = []

      if (
        !this.postFilter ||
        !this.postFilter.months ||
        !this.postFilter.months.length
      ) {
        return this.clearResult()
      }

      this.values = this.postFilter.months
      this.filterInitialized = true
    },
    async executeFilter() {
      if (!this.filterInitialized) {
        this.values = this.postFilter.months
        this.filterInitialized = true
        return
      }

      const filter = { months: this.values }
      this.$emit('filter', filter)
    },

    open() {
      if (this.firstOpen && this.monthsOptions.length) {
        this.$refs.multiSelectMonths.selectTab(this.monthsOptions.length - 1)
        this.firstOpen = false
      }
    }
  },
  mounted() {
    if (this.artist) {
      this.initFilter()
    }

    this.$root.$on('recoverPostFilter', this.restoreFilter)
    this.filterInitialized = true
  },
  beforeDestroy() {
    this.$root.$off('recoverPostFilter', this.restoreFilter)
  }
}
</script>
