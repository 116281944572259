<template>
  <section class="hero is-medium is-primary" v-if="artist">
    <div v-if="!isAuthenticated" class="hero-body">
      <div class="container has-text-centered">
        <h3 class="title is-2">
          getnext.to <strong>{{ artist.nickname }}</strong>, {{ $t('andImmediately') }}
        </h3>
        <h4 class="subtitle is-4">{{ $t('getExclusiveAccess') }}</h4>

        <a href="#" @click="showFollowerModal = true" class="button is-outlined is-white is-large mt-20">
          <strong>{{ $t('signUpNow') }}</strong>
        </a>
      </div>
      <modal v-if="showFollowerModal" @close="closeFollowModal" :backdropClose="true" :defaultHeader="false"
        :defaultFooter="true">
        <div slot="body">
          <div class="is-overlay">
            <section class="scale-hack">
              <div class="content">
                <div class="
                    modal-card
                    animated
                    slideInDown
                    login-modal
                    pt-10
                    pl-10
                    pr-10
                  ">
                  <follower-login @logged="closeFollowModal"></follower-login>
                </div>
              </div>
            </section>
          </div>
        </div>
      </modal>
    </div>
    <div v-else-if="user.nickname" class="hero-body">
      <div class="container has-text-centered">
        <h3 class="title is-2">
          {{ $t('hey') }} <strong>{{ user.nickname }}</strong>! getnext.to <strong>{{ artist.nickname }}</strong>, {{
            $t('andImmediately') }}
        </h3>
        <h4 class="subtitle is-4">{{ $t('getExclusiveAccess') }}</h4>
        <div class="cta-purchasables">
          <nuxt-link :to="localePath({
            name: 'id-products',
            params: { id: artist.urlAlias }
          })
            " v-if="artist.products.length" class="button is-outlined is-white is-large mt-20"><strong>{{
              $t('availableSingleRewards') }}</strong>
          </nuxt-link>
          <nuxt-link :to="localePath({
            name: 'id-rewards',
            params: { id: artist.urlAlias }
          })
            " v-if="artist.rewards.length" class="button is-outlined is-white is-large mt-20"><strong>{{
              $t('availableRewards') }}</strong>
          </nuxt-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import followerLogin from '~/components/follower-login'
import modal from '~/components/modal'

export default {
  props: ['user', 'artist', 'isAuthenticated'],
  components: { modal, followerLogin },
  data: () => ({
    showFollowerModal: false
  }),
  methods: {
    closeFollowModal() {
      this.showFollowerModal = false
    }
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 1023px) {
  .cta-purchasables {
    .button+.button {
      margin-left: 0px !important;
    }
  }
}
</style>
