<template>
  <div>
    <section>
      <vue-multi-select
        ref="multiSelectMonths"
        class="multi-select filter-content"
        :class="{ 'filter-content-mobile': layout === 'mobileFilterPosts' }"
        v-model="values"
        :options="options"
        :filters="filters"
        :btnLabel="btnLabel"
        :position="layout === 'mobileFilterPosts' ? 'bottom-right' : 'bottom-left'"
        :searchPlaceholder="`${$t('search')}...`"
        @selectionChanged="executeFilter"
        :selectOptions="contentOptions">
      </vue-multi-select>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import vueMultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'

export default {
  props: ['layout'],
  components: { vueMultiSelect },
  data: () => ({
    name: 'Filter-Posts-Content',
    values: [],
    options: {
      multi: true,
      labelValue: '_id',
      cssSelected: (option) => option['selected'] ? { 'font-weight': 'bold', color: '#fb3e52' } : ''
    },
    filters: [{
      nameAll: 'select all',
      nameNotAll: 'unselect all',
      func() {
        return true
      }
    }],
    filterInitialized: false
  }),
  computed: {
    contentOptions() {
      return [{
        _id: 'video',
        name: '🎥 Videos'
      }, {
        _id: 'audio',
        name: '🎧  Audios'
      }, {
        _id: 'image',
        name: '📸  Images'
      }, {
        _id: 'zip',
        name: '🗜️  Zips'
      }, {
        _id: 'pdf',
        name: '📄  PDF'
      }]
    },
    selectAllLabel() {
      return this.$('selectAll')
    },
    deselectAllLabel() {
      return this.$('deselectAllLabel')
    },
    ...mapState(['artist', 'profilePostsLoaded', 'postFilter'])
  },
  methods: {
    btnLabel() {
      return this.$t('filterByContent') + ((this.values || []).length ? ` (${this.values.length})` : '')
    },

    cssSelected(option) {
      return option['selected'] ? {'color': 'green'} : ''
    },

    clearResult() {
      this.values = []
    },
    initFilter() {
      this.filters[0].nameAll = this.$t('selectAll')
      this.filters[0].nameNotAll = this.$t('deselectAll')
    },
    restoreFilter() {
      this.filterInitialized = false

      if (
        !this.postFilter ||
        !this.postFilter.content ||
        !this.postFilter.content.length
      ) {
        return this.clearResult()
      }

      this.values = this.postFilter.contents
      this.filterInitialized = true
    },
    async executeFilter() {
      if (!this.filterInitialized) {
        this.values = this.postFilter.contents
        this.filterInitialized = true
        return
      }
      const filter = { contents: this.values }
      this.$emit('filter', filter)
    }
  },
  mounted() {
    if (this.artist) {
      this.initFilter()
    }

    this.$root.$on('recoverPostFilter', this.restoreFilter)
    this.filterInitialized = true
  },
  beforeDestroy() {
    this.$root.$off('recoverPostFilter', this.restoreFilter)
  }
}
</script>

<style lang="scss">
.filter-content {
  .checkboxLayer {
    min-width: 222px !important;
    max-width: 222px;
  }
  &.filter-content-mobile {
    .checkboxLayer {
      min-width: 120px !important;
      max-width: 120px;
    }
  }
}
</style>
